import moment from 'moment';
import type {FmUser, RawFmUser} from '@/types/fmUser';

const formats = [
    // moment.ISO_8601,
    'MM/DD/YYYY',
    'M/D/YYYY',
    'MM/DD/YYYY HH:mm:ss',
    'M/D/YYYY HH:mm:ss',
];

const parseDate = (stringDate : string) => {
    const theDate = moment(stringDate, formats, true);

    if (theDate.isValid()) {
        return theDate.toDate();
    }

    return null;
};

export const mapRawToFmUser = (raw : RawFmUser) : FmUser => {
    return {
        name: `${raw.fieldData['Title']} ${raw.fieldData['First']} ${raw.fieldData['Last']}`,
        company: raw.fieldData['Company'],
        phoneNumber: raw.fieldData['Office Phone'],
        email: raw.fieldData['Email'],
        ID: raw.fieldData['CUSTOMER ID'],
        dateAcceptedTermsAndConditions: parseDate(raw.fieldData['Agreement_Approved_Date']),
        projects: raw.projects.map(project => {
            return {
                recordId: project.fieldData.recordId,
                creationDate: parseDate(project.fieldData['Creation Timestamp']),
                jobNumber: project.fieldData['JOB NO ID'],
                projectName: project.fieldData['Master Project Name'],
                dueDate: parseDate(project.fieldData['Customer Due Date Final']),
                status: project.fieldData['z_Status_Bucket'],
                statusDate: parseDate(project.fieldData['z_Status_Bucket_Update_Date']),
                comments: project.fieldData['z_Status_Bucket_Canned_Reasons'],
                poNumber: project.fieldData['z_Purchase_Order_Numbers'],
                modId: project.fieldData.modId,
            };
        }),
    };
};
