import {Box, Link, Stack} from '@mui/material';
import {MaterialReactTable, type MRT_ColumnDef, useMaterialReactTable} from 'material-react-table';
import type * as React from 'react';
import {useMemo, useState} from 'react';
import ProjectModal from '@/components/ProjectModal';
import type {Project} from '@/types/fmUser';

type Props = {
    data : Project[];
};

const ProjectTable = ({data} : Props) : React.ReactElement => {
    const [selectedProject, setSelectedProject] = useState<Project | undefined>();

    const columns = useMemo<Array<MRT_ColumnDef<Project>>>(
        () => [
            {
                accessorKey: 'creationDate',
                header: 'Creation Date',
                size: 150,
                sortingFn: 'datetime',
                accessorFn: row => {
                    return row.creationDate === null ? '' : row.creationDate.toLocaleString();
                },
            },
            {
                accessorKey: 'jobNumber',
                header: 'Job Number',
                size: 200,
                Cell: ({cell}) => {
                    return <Link
                        href="#"
                        onClick={() => {
                            setSelectedProject(cell.row.original);
                        }}
                    >
                        {cell.getValue() as string}
                    </Link>;
                },
            },
            {
                accessorKey: 'projectName',
                header: 'Project Name',
                size: 150,
            },
            {
                accessorKey: 'poNumber',
                header: 'PO #',
                size: 150,
            },
            {
                accessorKey: 'status',
                header: 'Status',
                size: 150,
            },
            {
                accessorKey: 'dueDate',
                header: 'Due Date',
                size: 150,
                sortingFn: 'datetime',
                accessorFn: row => {
                    return row.dueDate === null ? '' : row.dueDate.toLocaleString();
                },
            },
        ],
        [],
    );

    const table = useMaterialReactTable({
        columns,
        data,
        // renderTopToolbarCustomActions: () => <Stack
        //     sx={{
        //         flexDirection: 'column',
        //         width: '100%',
        //     }}
        // >
        //     <Stack
        //         sx={{
        //             flexDirection: 'row',
        //             justifyContent: 'center',
        //             width: '100%',
        //         }}
        //     >
        //         <Box sx={{typography: 'h5', p: 2}}>My Projects</Box>
        //     </Stack>
        // </Stack>,
        enableColumnActions: false,
        enableColumnFilters: false,
        enableDensityToggle: false,
        enableFilterMatchHighlighting: false,
        enableFullScreenToggle: false,
        enableGlobalFilter: true,
        enableHiding: false,
        enablePagination: false,
        initialState: {
            showGlobalFilter: true,
            sorting: [
                {id: 'creationDate', desc: false},
                {id: 'jobNumber', desc: false},
            ],
        },

    });

    return <Box>
        <MaterialReactTable table={table}/>
        <ProjectModal setSelectedProject={setSelectedProject} selectedProject={selectedProject}/>
    </Box>;
};

export default ProjectTable;
